function switchDisplay(bullet) {
	const bullets = document.querySelectorAll('.bullet-point');
	const texts = document.querySelectorAll('.training-text');

	// change bullet point background
	[...bullets].forEach(element => element.classList.remove('bg-black'));
	bullet.classList.add('bg-black');

	// change displayed text
	const id = bullet.id.replace('bullet-', '')
	texts.forEach(text => text.classList.add('d-none'));
	document.getElementById(id).classList.remove('d-none')
}
export { switchDisplay };
