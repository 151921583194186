
function updateFtp(np, ftp) {
  np.addEventListener('keyup', e => {
    ftp.value = Math.round(+np.value * 0.951243);
  });
}

function updateNp(np, ftp) {
  ftp.addEventListener('keyup', e => {
    np.value = Math.round(+ftp.value * 1.0526);
  });
}

export { updateFtp, updateNp };
