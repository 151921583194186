var filter = {type: '', home_trainer: ''};
function filterBikeTests(element) {
  let bikeTestType = element.dataset.bikeTestType
  let homeTrainer = element.dataset.homeTrainer

  if (bikeTestType) {
    const selected = element.classList.contains('toggle-bike-test-type');
    if (selected) {
      filter.type = '';
      element.classList.remove('toggle-bike-test-type');
    } else {
      filter.type = bikeTestType
      document.querySelectorAll('.toggle-bike-test-type').forEach(element => element.classList.remove('toggle-bike-test-type'));
      element.classList.add('toggle-bike-test-type');
    }
  }
  if (homeTrainer) {
    const selected = element.classList.contains('toggle-bike-ht');
    if (selected) {
      filter.home_trainer = '';
      element.classList.remove('toggle-bike-ht');
    } else {
      filter.home_trainer = homeTrainer;
      document.querySelectorAll('.toggle-bike-ht').forEach(element => element.classList.remove('toggle-bike-ht'));
      element.classList.add('toggle-bike-ht');
    }
  }
  
  $.ajax({
    url: '/bike_tests',
    type: "GET",
    dataType: 'script',
    data: { filter: filter },
  });
}

export { filterBikeTests }; 
