import { elementClosest } from "@fullcalendar/core";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['sport', 'week', 'label'];

  connect() {
    this.trainingResources = [...document.getElementById('training-resources').children];
    this.updateTrainings();
  }

  updateTrainings() {
    this.trainingResources.forEach(el => hide(el));
    this.trainingResources.forEach(el => {
      const isConditionMet = el.dataset.sport == this.sportTarget.value &&
                             el.dataset.week == this.weekTarget.value &&
                             el.dataset.label.split('-').includes(this.labelTarget.value)
      isConditionMet ? display(el) : hide(el)
    })
  }
}

const display = element => {
  element.classList.remove('d-none');
  element.classList.add('d-flex');
}

const hide = element => {
  element.classList.remove('d-flex');
  element.classList.add('d-none');
}
