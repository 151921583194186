function showRaceFormat(value) {
  $.ajax({
    url: '/goals/new',
    type: "get",
    dataType: 'script',
    data: { race_type_value: value }
  });
}

function showRaceDistances(value) {
  var race_type = document.getElementById('goal_race_type').value
  $.ajax({
    url: '/goals/new',
    type: "get",
    dataType: 'script',
    data: { race_format: value, race_type: race_type }
  });
}

export { showRaceFormat, showRaceDistances };
