export function updateTrainingWeekDetails() {
  const prevButton = document.querySelector('.fc-prev-button');
  const nextButton = document.querySelector('.fc-next-button');

  nextButton.addEventListener('click', e => {
    call();
  });
  prevButton.addEventListener('click', e => {
    call()
  });

  function call() {
    $.ajax({
      url: '/training_weeks/',
      type: 'GET',
      data: { beginning_of_week: document.querySelector('[data-date]').dataset.date },
      dataType: 'script'
    });
  }
}

export function displayTrainingWeekDetails() {
  const viewButtons = [document.querySelector('.fc-dayGridMonth-button'), document.querySelector('.fc-dayGridWeek-button'), document.querySelector('.fc-listWeek-button')];
  viewButtons.forEach(button => {
    button.addEventListener('click', event => {
      if (isWeekViewActive()) {
        document.getElementById('week-details').classList.remove('d-none');
        document.getElementById('week-details').classList.add('d-flex');
      } else {
        document.getElementById('week-details').classList.remove('d-flex');
        document.getElementById('week-details').classList.add('d-none');
      }
    });
  });
}

function isWeekViewActive() {
  return document.querySelector('.fc-dayGridWeek-button').classList.contains('fc-button-active');
}
