import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

const initFlatPickr = () => {
  flatpickr(".datepicker", {
    altInput: true,
    locale: French
  });
};

const trainingFlatPickr = () => {
  flatpickr(".datepicker", {
    altInput: true,
    locale: French,
    defaultDate: document.querySelector('[data-date]').dataset.date
  });
};

const flatpickrFcs = (datepickers) => {
  datepickers.forEach(datepicker => {
    let current_date
    if (datepicker.dataset.date) {
      current_date = new Date(datepicker.dataset.date)
    }
    flatpickr(datepicker, {
      locale: French,
      dateFormat: "d/m/Y",
      defaultDate: current_date || ''
    });
  });
}

export { initFlatPickr, trainingFlatPickr, flatpickrFcs };
