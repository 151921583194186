var filter = {type: ''};
function filterSwimTests(element) {
  let distance = element.dataset.distance

  const selected = element.classList.contains('toggle-swim-distance')
  if (selected) {
    filter.type = '';
    element.classList.remove('toggle-swim-distance');
  } else {
    filter.type = distance;
    document.querySelectorAll('.toggle-swim-distance').forEach(element => element.classList.remove('toggle-swim-distance'));
    element.classList.add('toggle-swim-distance');
  }

  $.ajax({
    url: '/swim_tests',
    type: "GET",
    dataType: 'script',
    data: { filter: filter }
  });
}

export { filterSwimTests };
