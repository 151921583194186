import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [];
  static values = {
    url: String
  };

  select(event) {
    const sport = event.currentTarget.dataset.sport;
    window.localStorage.setItem('sport', sport);
    [...document.querySelectorAll('.label-training.active')].map(label => label.classList.remove('active'));
    [...document.querySelectorAll('.sport-list-item')].map(item => item.classList.remove('active'));
    event.currentTarget.classList.add('active');
    displaySpinner()
    this.call({
      sport: sport,
      values: JSON.parse(window.localStorage.getItem('labels')) || []
    })
  }

  filter(event) {
    const sport = document.getElementById('trainings').dataset.sport;
    if (event) { event.currentTarget.classList.toggle('active') };
    let currentLabels = JSON.parse(window.localStorage.getItem('labels')) || [];
    currentLabels.includes(event.currentTarget.innerText) ? currentLabels = currentLabels.filter(el => el !== event.currentTarget.innerText) : currentLabels.push(event.currentTarget.innerText)
    window.localStorage.setItem('labels', JSON.stringify(currentLabels))
    this.call({
      sport: sport,
      values: currentLabels
    })
  }

  clean() {
    const sport = document.getElementById('trainings').dataset.sport;
    [...document.querySelectorAll('.label-training.active')].map(label => label.classList.remove('active'));
    this.call({
      sport: sport
    })
  }

  call = data => {
    $.ajax({
      url: '/admin/libraries',
      type: 'GET',
      data: data,
      dataType: 'json'
    }).then(data => {
      document.getElementById('trainings').outerHTML = data.html;
      data.label_ids.forEach(label_id => {
        document.getElementById(`label_${label_id}`).classList.add('active');
      });
    })
  }
}


const displaySpinner = () => {
  document.getElementById('trainings').classList.add('text-center');
  document.getElementById('trainings').innerHTML = '<div class="spinner-grow text-secondary" style="width: 100px; height: 100px;" role="status"></div>';
}
