import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  static targets = ['input']
  static values = {}

  connect() {
    flatpickr(this.inputTarget, {
      altInput: true,
      disableMobile: "true",
      locale: French
    });
  }
}
