import Sortable from 'sortablejs';

export const templateSortable = () => {
  new Sortable(document.getElementById('training-resources'), {
    group: {
        name: 'shared',
        pull: 'clone',
        put: false // Do not allow items to be put into this list
    },
    animation: 150,
    sort: false, // To disable sorting: set sort to false
    onEnd: function(event) {
      if (!event.to.hasAttribute('data-week-id')) { return };

      fetch('/admin/training_assignments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          training_assignment: {
            training_week_id: +event.to.dataset.weekId,
            training_resource_id: +event.item.id.split('_')[2]
          }
        })
      }).then(response => response.json())
        .then((data) => {
          if (data.id == null) {
            document.getElementById(`training_resource_${data.training_resource_id}`).remove();
          }
        });
    }
  });

  [...document.querySelectorAll('.sessions-content')].forEach(session => {
    new Sortable(session, {
      group: 'shared',
      animation: 150,
      sort: false
    });
  });
}

export const templateWeek = () => {
  let trainings = [];
  [...document.querySelectorAll('.available_sessions .sessions')].forEach(session => {
    new Sortable(session, {
      group: 'shared',
      animation: 150,
      sort: false, // To disable sorting: set sort to false
      onEnd: function(event) {
        if (event.to.classList.contains('schedule_body_day')) {
          const name = `${event.item.dataset.sport}_${event.item.dataset.sessionType}_${event.to.dataset.day}`;
          const sessionObj = { sport: event.item.dataset.sport, session_type: event.item.dataset.sessionType, day: event.to.dataset.day, name: name }
          trainings.push(sessionObj)
          localStorage.setItem('planning_trainings', JSON.stringify(trainings))
        }
      }
    });
  });

  [...document.querySelectorAll('.schedule .schedule_body_day_inner')].forEach(session => {
    new Sortable(session, {
      group: 'shared',
      animation: 150,
      onEnd: function(event) {
        const elements = JSON.parse(localStorage.getItem('planning_trainings'));
        const prevName = `${event.item.dataset.sport}_${event.item.dataset.sessionType}_${event.from.dataset.day}`;
        const prevElement = elements.find(el => el.name === prevName);
        trainings = trainings.filter(el => el.name != prevElement.name)

        if (event.to.classList.contains('schedule_body_day')) {
          const name = `${event.item.dataset.sport}_${event.item.dataset.sessionType}_${event.to.dataset.day}`;
          const sessionObj = { sport: event.item.dataset.sport, session_type: event.item.dataset.sessionType, day: event.to.dataset.day, name: name }
          trainings.push(sessionObj)
        }

        localStorage.setItem('planning_trainings', JSON.stringify(trainings))
      }
    });
  });
}
