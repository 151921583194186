import Swal from 'sweetalert2';

const removeElement = (element) => {
  const url = element.dataset.url;
  const type = element.dataset.testType;
  Swal.fire({
    title: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    icon: 'warning',
    confirmButtonText: 'Oui',
    showCancelButton: true,
    cancelButtonText: 'Non',
    reverseButtons: true
  }).then(result => {
    if (result.value) {
      $.ajax({
        url: url,
        type: "DELETE",
        dataType: 'script',
        data: {type: type}
      });
    }
  });
};

const removeGoalWithPlanning = (element) => {
  const url = element.dataset.url;
  const type = element.dataset.testType;
  Swal.fire({
    title: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    icon: 'warning',
    confirmButtonText: 'Oui',
    showCancelButton: true,
    cancelButtonText: 'Non',
    reverseButtons: true
  }).then(result => {
    if (result.value) {
      Swal.fire({
        title: 'Continuer ?',
        text: 'La suppresion de cet objectif entrainera la suppression du programme associé',
        icon: 'warning',
        confirmButtonText: 'Oui',
        showCancelButton: true,
        cancelButtonText: 'Non',
        reverseButtons: true
      }).then(secondResult => {
        if (secondResult.value) {
          $.ajax({
            url: url,
            type: "DELETE",
            dataType: 'script',
            data: {type: type}
          });
        }
      });
    }
  });
};

export { removeElement, removeGoalWithPlanning };
